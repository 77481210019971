import React from "react";
import "./App.css";
import SortingVisualizer from "./pages/SortingVisualizer/sortingvisualizer";

function App() {
    return (
        <>
            <div className="App" id="app">
                <SortingVisualizer></SortingVisualizer>
            </div>
        </>
    );
}

export default App;
