import React, { useState, useEffect } from "react";
import "./sortingvisualizer.css";
import { getMergeSortAnimations } from "./SortingAlgorithms/mergeSort.js";
import { getHeapSortAnimations } from "./SortingAlgorithms/heapSort.js";
import { getQuickSortAnimations } from "./SortingAlgorithms/quickSort.js";
import { AiOutlineSync } from "react-icons/ai";
import { getShellSortAnimations } from "./SortingAlgorithms/shellSort.js";
import { Modal } from "@mui/material";
import { AiOutlineCloseCircle, AiFillInfoCircle } from "react-icons/ai";
import About from "../About/About.js";
import History from "../../components/History/History.js";

const PRIMARY_COLOR = "#DAD2BC";
const SECONDARY_COLOR = "#2EBFA5";
const SECONDARY_COLOR_TWO = "#C5D86D";

const SortingVisualizer = () => {
    const [array, setArray] = useState([]);
    const [arrayBars, setArrayBars] = useState(23);
    const [speed, setSpeed] = useState(200);
    const [showOption, setShowOption] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [history, setHistory] = useState(() => {
        const initialHistory = localStorage.getItem("sortHistory");
        return initialHistory ? JSON.parse(initialHistory) : [];
    });
    const [sort, setSort] = useState({});
    const [tempArray, setTempArray] = useState([]);

    const handleChangeSpeed = (e) => {
        const transformedValue = 400 - parseInt(e.target.value, 10);
        setSpeed(transformedValue);
    };

    const handleChangeBars = (e) => {
        setArrayBars(e.target.value);
        resetArray();
    };

    const handleArrayBarsChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value < 1 || value > 40) {
            return;
        }
        setArrayBars(isNaN(value) ? 0 : value);
    };

    const resetArray = (message) => {
        let newArray = Array.from({ length: arrayBars }, () => randomIntBetween(5, 50));
        if (message === "reset") {
            if (window.confirm("Bạn có muốn giữ lại mảng này")) {
                newArray = tempArray;
            }
        }

        setArray(newArray);
        setTempArray(newArray);

        document.querySelectorAll(".array-bar").forEach((item, index) => {
            item.setAttribute(
                "style",
                `background: ${PRIMARY_COLOR} !important; height: ${array[index] * 10}px !important`
            );
        });
        const numOfDisabled = document.getElementsByClassName("btn disabled").length;
        let resetButtons;
        if (numOfDisabled > 0) resetButtons = true;
        let id = window.setTimeout(() => {}, 0);
        while (id--) {
            window.clearTimeout(id);
        }
        const settingSliders = document.getElementsByClassName("slider");
        for (let i = 0; i < settingSliders.length; i++) {
            settingSliders[i].disabled = false;
        }
        toggleButtons(resetButtons, "all");
    };

    const toggleButtons = (onOrOff, allOrGen, animationsLength) => {
        const buttons = document.getElementsByClassName("btn disabled");
        const settingSliders = document.getElementsByClassName("slider");
        if (onOrOff === false) {
            const sortButtons = document.getElementsByClassName("btn sort");
            while (sortButtons.length > 0) {
                sortButtons[0].className = "btn disabled";
                buttons[buttons.length - 1].className = "btn disabled";
            }
            for (let i = 0; i < settingSliders.length; i++) {
                settingSliders[i].disabled = true;
            }
            const genArrayButton = document.getElementsByClassName("btn");
            genArrayButton[0].className = "btn disabled";
            genArrayButton[genArrayButton.length - 1].className = "btn disabled";
        } else if (onOrOff === true && allOrGen === "gen") {
            setTimeout(() => {
                buttons[0].className = "btn relative group";
                buttons[buttons.length - 1].className = "btn relative group";
                for (let i = 0; i < settingSliders.length; i++) {
                    settingSliders[i].disabled = false;
                }
            }, animationsLength * speed);
        } else if (onOrOff === true && allOrGen === "all") {
            while (buttons.length > 0) {
                buttons[0].className = "btn sort group";
                buttons[buttons.length - 1].className = "btn sort group";
            }
        }
    };

    const handleConfirmButtonClick = () => {
        const numOfDisabled = document.getElementsByClassName("btn disabled").length;
        let resetButtons;
        if (numOfDisabled > 0) resetButtons = true;
        let id = window.setTimeout(() => {}, 0);
        while (id--) {
            window.clearTimeout(id);
        }
        const settingSliders = document.getElementsByClassName("slider");
        for (let i = 0; i < settingSliders.length; i++) {
            settingSliders[i].disabled = false;
        }
        toggleButtons(resetButtons, "all");
        const newArray = Array.from({ length: arrayBars }, (_, index) => {
            const inputElement = document.getElementById(`input-${index}`);
            const inputValue = inputElement ? inputElement.value : "";
            const parsedValue = parseInt(inputValue, 10) || 0;
            if (parsedValue >= 0 && parsedValue <= 50) {
                return parsedValue;
            } else {
                return 0;
            }
        });

        if (newArray.includes(0) || arrayBars <= 0) {
            alert("Vui lòng nhập giá trị từ 1 đến 50 cho tất cả các ô input.");
        } else {
            setArray(newArray);
            setTempArray(newArray);
            setShowOption(false);
        }
    };

    const mergeSort = () => {
        const tempArrayTest = [...array];
        const animations = getMergeSortAnimations(tempArrayTest);
        toggleButtons(false);
        toggleButtons(true, "gen", animations.length);
        let timer = Date.now();
        let size = arrayBars;
        for (let i = 0; i < animations.length; i++) {
            const arrayBars = document.getElementsByClassName("array-bar");
            const isColorChange = i % 3 === 0 || i % 3 === 2;
            if (isColorChange) {
                const [barOneIdx, barTwoIdx] = animations[i];
                const barOneStyle = arrayBars[barOneIdx].style;
                const barTwoStyle = arrayBars[barTwoIdx].style;
                if (i % 3 === 0) {
                    setTimeout(() => {
                        barOneStyle.backgroundColor = SECONDARY_COLOR;
                        barTwoStyle.backgroundColor = SECONDARY_COLOR_TWO;
                    }, i * speed);
                }
                if (i % 3 === 2) {
                    setTimeout(() => {
                        barOneStyle.backgroundColor = PRIMARY_COLOR;
                        barTwoStyle.backgroundColor = PRIMARY_COLOR;
                        if (i === animations.length - 1) {
                            const array1 = history[0];
                            const end = Date.now();
                            const elapsedTime = end - timer;
                            const checkValue = checkSameArray(
                                array1?.array,
                                array,
                                array1?.speed,
                                400 - speed,
                                array1?.type,
                                "MergeSort"
                            );
                            if (checkValue === 1) {
                                updateLocalStorage({
                                    group: array1?.group || 1,
                                    array: [...array],
                                    type: "MergeSort",
                                    size,
                                    speed: 400 - speed,
                                    timer: (elapsedTime / 1000).toFixed(3),
                                    date: getDateTime(),
                                });
                            } else if (checkValue === 2) {
                                setSort({
                                    group: array1?.group || 1,
                                    array: [...array],
                                    type: "MergeSort",
                                    size,
                                    speed: 400 - speed,
                                    timer: (elapsedTime / 1000).toFixed(3),
                                    date: getDateTime(),
                                });
                            } else {
                                setSort({
                                    group: array1?.group + 1 || 1,
                                    array: [...array],
                                    type: "MergeSort",
                                    size,
                                    speed: 400 - speed,
                                    timer: (elapsedTime / 1000).toFixed(3),
                                    date: getDateTime(),
                                });
                            }
                        }
                    }, i * speed);
                }
            } else {
                setTimeout(() => {
                    const [barOneIdx, newHeight] = animations[i];
                    const barOneStyle = arrayBars[barOneIdx].style;
                    barOneStyle.height = `${newHeight * 10}px`;
                    const valueElement = arrayBars[barOneIdx].querySelector(".value");
                    valueElement.innerHTML = `${newHeight}`;
                }, i * speed);
            }
        }
    };
    const quickSort = () => {
        const tempArrayTest = [...array];
        const animations = getQuickSortAnimations(tempArrayTest);
        toggleButtons(false);
        toggleButtons(true, "gen", animations.length);
        showSwap(animations, "QuickSort");
    };

    const heapSort = () => {
        const tempArrayTest = [...array];
        const animations = getHeapSortAnimations(tempArrayTest);
        toggleButtons(false);
        toggleButtons(true, "gen", animations.length);
        showSwap(animations, "HeapSort");
    };

    const shellSort = () => {
        const tempArrayTest = [...array];
        const animations = getShellSortAnimations(tempArrayTest);
        toggleButtons(false);
        toggleButtons(true, "gen", animations.length);
        showSwap(animations, "ShellSort");
    };

    const getDateTime = () => {
        const now = new Date();
        const offsetMs = now.getTimezoneOffset() * 60 * 1000;
        const dateLocal = new Date(now.getTime() - offsetMs);
        const str = dateLocal.toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
        return str;
    };

    const checkSameArray = (array1, array2, speed1, speed2, type1, type2) => {
        let areEqual = array1?.every((value, index) => value === array2[index]);
        if (areEqual && speed1 === speed2 && array1) {
            if (type1 === type2) {
                return 1;
            } else {
                return 2;
            }
        } else {
            return 0;
        }
    };

    const updateLocalStorage = ({ sort }) => {
        const existingIndex = history.findIndex((item) => item?.type === sort?.type && item.group === sort.group);
        if (existingIndex !== -1 && history.length !== 0) {
            history[existingIndex] = sort;
        } else {
            setSort(sort);
        }
    };
    const showSwap = (animations, type) => {
        let timer = Date.now();
        let size = arrayBars;
        for (let i = 0; i < animations.length; i++) {
            const arrayBars = document.getElementsByClassName("array-bar");
            let order = i % 4;
            if (order === 0) {
                let indexOne = animations[i][0];
                let indexTwo = animations[i][1];

                setTimeout(() => {
                    arrayBars[indexOne].style.backgroundColor = SECONDARY_COLOR;
                    arrayBars[indexTwo].style.backgroundColor = SECONDARY_COLOR_TWO;
                }, i * speed);
            } else if (order === 3) {
                let indexOne = animations[i][0];
                let indexTwo = animations[i][1];

                setTimeout(() => {
                    arrayBars[indexOne].style.backgroundColor = PRIMARY_COLOR;
                    arrayBars[indexTwo].style.backgroundColor = PRIMARY_COLOR;
                    if (i === animations.length - 1) {
                        const array1 = history[0];
                        const end = Date.now();
                        const elapsedTime = end - timer;
                        const checkValue = checkSameArray(
                            array1?.array,
                            array,
                            array1?.speed,
                            400 - speed,
                            array1?.type,
                            type
                        );
                        if (checkValue === 1) {
                            updateLocalStorage({
                                group: array1?.group || 1,
                                array: [...array],
                                type,
                                size,
                                speed: 400 - speed,
                                timer: (elapsedTime / 1000).toFixed(3),
                                date: getDateTime(),
                            });
                        } else if (checkValue === 2) {
                            setSort({
                                group: array1?.group || 1,
                                array: [...array],
                                type,
                                size,
                                speed: 400 - speed,
                                timer: (elapsedTime / 1000).toFixed(3),
                                date: getDateTime(),
                            });
                        } else {
                            setSort({
                                group: array1?.group + 1 || 1,
                                array: [...array],
                                type,
                                size,
                                speed: 400 - speed,
                                timer: (elapsedTime / 1000).toFixed(3),
                                date: getDateTime(),
                            });
                        }
                    }
                }, i * speed);
            } else if (order === 1 || order === 2) {
                let indexToChange = animations[i][0];
                let newHeight = animations[i][1];

                setTimeout(() => {
                    arrayBars[indexToChange].style.height = `${newHeight * 10}px`;
                    arrayBars[indexToChange].querySelector(".value").innerHTML = `${newHeight}`;
                }, i * speed);
            }
        }
    };

    const handleDeleteHistory = () => {
        if (window.confirm("Bạn có muốn xóa tất các các lịch sử sắp xếp không ?")) {
            localStorage.setItem("sortHistory", JSON.stringify([]));
            setHistory([]);
        }
    };

    useEffect(() => {
        resetArray();
    }, []);

    useEffect(() => {
        if (sort?.type) {
            setHistory((history) => [sort, ...history]);
        }
    }, [sort]);

    useEffect(() => {
        localStorage.setItem("sortHistory", JSON.stringify(history));
    }, [history]);
    return (
        <div>
            <header className="top pt-[20px] ">
                <button title="Stop animation and reset array" className="stop-button">
                    <p id="stop-link" onClick={() => resetArray("reset")}>
                        <AiOutlineSync id="stop-icon" />
                    </p>
                </button>
                <div className="speedSlider">
                    <div className="name-slider">Tốc độ</div>
                    <input
                        title="Drag left to make animation faster"
                        className="slider"
                        type="range"
                        min={50}
                        max={350}
                        value={400 - speed}
                        onChange={handleChangeSpeed}
                    />
                </div>
                <div className="barSlider">
                    <div className="name-slider">Số lượng</div>
                    <input
                        title="Drag right to increase the number of bars"
                        className="slider"
                        type="range"
                        min={10}
                        max={39}
                        value={arrayBars}
                        onChange={handleChangeBars}
                    />
                </div>
                <h1 id="title" className="text-center h-[20px]">
                    {/* Sorting Algorithms */}
                </h1>
                <div className="button-bar mx-auto flex justify-center items-center ">
                    <button className="btn relative group" id="new">
                        Tạo Danh sách mới
                        <ul className="absolute bottom-[-60px] w-full left-0 text-[12px]  p-1 shadow-lg text-left rounded-md group-hover:block hidden bg-[#3a4d5c]">
                            <li onClick={() => resetArray()} className="border-b-[1px] p-1 hover:opacity-90">
                                Danh sách ngẫu nhiên
                            </li>
                            <li className="p-1 hover:opacity-90" onClick={() => setShowOption(true)}>
                                Danh sách tự nhập
                            </li>
                        </ul>
                    </button>
                    <button
                        className="btn sort"
                        onClick={() => {
                            quickSort();
                        }}
                    >
                        Quick Sort
                        <AiFillInfoCircle
                            className="absolute -top-3 -right-3 bg-transparent hover:opacity-80 hover:text-white text-[22px]"
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalType("Thuật Toán Quicksort");
                            }}
                        />
                    </button>
                    <button className="btn sort" onClick={() => mergeSort()}>
                        Merge Sort
                        <AiFillInfoCircle
                            className="absolute -top-3 -right-3 bg-transparent hover:opacity-80 hover:text-white text-[22px]"
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalType("Thuật Toán Mergesort");
                            }}
                        />
                    </button>
                    <button className="btn sort" onClick={() => heapSort()}>
                        Heap Sort
                        <AiFillInfoCircle
                            className="absolute -top-3 -right-3 bg-transparent hover:opacity-80 hover:text-white text-[22px]"
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalType("Thuật Toán Heapsort");
                            }}
                        />
                    </button>
                    <button className="btn sort" onClick={() => shellSort()}>
                        Shell Sort
                        <AiFillInfoCircle
                            className="absolute -top-3 -right-3 bg-transparent hover:opacity-80 hover:text-white text-[22px]"
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalType("Thuật Toán Shellsort");
                            }}
                        />
                    </button>

                    <History history={history} handleDeleteHistory={handleDeleteHistory} />
                </div>
                <Modal
                    open={showOption}
                    onClose={() => setShowOption(false)}
                    className="flex justify-center items-center "
                >
                    <div className="w-[800px] h-[500px] p-5 relative rounded-[12px] bg-[#3a4d5c]">
                        <AiOutlineCloseCircle
                            className="absolute top-5 right-5 text-white text-[30px] cursor-pointer"
                            onClick={() => setShowOption(false)}
                        />
                        <h2 className="text-white text-center py-2 my-4 text-[24px] font-bold">
                            Nhập danh sách phần tử
                        </h2>
                        <div className="flex gap-2">
                            <h3 className="text-white">Nhập vào số lượng phần tử (1 - 40): </h3>
                            <input
                                type="number"
                                max={100}
                                className="bg-white w-[50px] px-3 rounded-[6px] text-center"
                                value={arrayBars}
                                onChange={handleArrayBarsChange}
                            />
                        </div>
                        <h3 className="text-white mt-5 mb-3">Nhập vào giá trị cho các phần tử (10 - 500): </h3>
                        <div className="flex flex-wrap gap-1">
                            {Array(arrayBars)
                                .fill(0)
                                .map((bar, index) => (
                                    <div className="flex items-end mb-4" key={index}>
                                        <div className="flex flex-col justify-center">
                                            <label
                                                htmlFor=""
                                                className="text-center text-white w-[50px] bg-blue-400 rounded-t-[6px]"
                                            >
                                                {index}
                                            </label>
                                            <input
                                                id={`input-${index}`}
                                                className="bg-white w-[50px]  px-2 mr-1 rounded-b-[6px] text-center"
                                            />
                                        </div>
                                        <span className="text-white"> ,</span>
                                    </div>
                                ))}
                        </div>
                        <div className="absolute bottom-5 right-5 flex gap-3">
                            <button
                                className="px-4 py-2 rounded-[4px] bg-gray-400 text-white hover:opacity-90"
                                onClick={() => {
                                    setShowOption(false);
                                    setArrayBars(26);
                                }}
                            >
                                Hủy
                            </button>
                            <button
                                className="px-4 py-2 rounded-[4px] bg-blue-400 text-white hover:opacity-90"
                                onClick={handleConfirmButtonClick}
                            >
                                Xác nhận
                            </button>
                        </div>
                    </div>
                </Modal>
                {modalType && <About type={modalType} setModalType={setModalType} />}
            </header>

            <div className="array-container flex justify-center items-end bg-[#3a4d5c]">
                {array.map((value, idx) => (
                    <div
                        className="array-bar"
                        key={idx}
                        style={{
                            height: `${value * 10}px`,
                            backgroundColor: PRIMARY_COLOR,
                        }}
                    >
                        <span className="value block text-center">{value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

function randomIntBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export default SortingVisualizer;
