import React, { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { nnfxDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { BsCheckLg, BsClipboard2 } from "react-icons/bs";

function HightLightCode({ codeString }) {
    const [copy, setCopy] = useState(false);

    return (
        <div className="bg-[#3a404d] rounded-md overflow-hidden">
            <div className="flex justify-between px-4 text-white text-xs items-center">
                <p>Mã nguồn mẫu</p>
                {copy ? (
                    <button className=" inline-flex items-center gap-1 justify-center py-2">
                        <span className="text-base">
                            <BsCheckLg />
                        </span>
                        Đã sao chép!
                    </button>
                ) : (
                    <button
                        className="inline-flex items-center gap-1 justify-center py-2"
                        onClick={() => {
                            navigator.clipboard.writeText(codeString);
                            setCopy(true);
                            setTimeout(() => {
                                setCopy(false);
                            }, 3000);
                        }}
                    >
                        <span className="text-base ">
                            <BsClipboard2 />
                        </span>
                        Sao chép
                    </button>
                )}
            </div>
            <SyntaxHighlighter
                showInlineLineNumbers={true}
                customStyle={{
                    padding: "25px",
                }}
                language="javascript"
                style={nnfxDark}
                wrapLines={true}
            >
                {codeString}
            </SyntaxHighlighter>
        </div>
    );
}

export default HightLightCode;
