import { Bar, Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { useEffect, useState } from "react";

export const SortingHistoryChart = ({ historyGroup }) => {
    const [label, setLabel] = useState([]);
    const [datasets, setDatasets] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const labels = historyGroup.reverse()?.map((group, index) => {
                return group[0].group;
            });

            const listData = [
                {
                    label: "QuickSort",
                    data: [],
                    backgroundColor: "white",
                    borderColor: "blue",
                    borderWidth: 3,
                    fill: false,
                },
                {
                    label: "MergeSort",
                    data: [],
                    backgroundColor: "white",
                    borderColor: "green",
                    borderWidth: 3,
                    fill: false,
                },
                {
                    label: "HeapSort",
                    data: [],
                    backgroundColor: "white",
                    borderColor: "red",
                    borderWidth: 3,
                    fill: false,
                },
                {
                    label: "ShellSort",
                    data: [],
                    backgroundColor: "white",
                    borderColor: "pulper",
                    borderWidth: 3,
                    fill: false,
                },
            ];

            const algorithms = ["QuickSort", "MergeSort", "HeapSort", "ShellSort"];

            for (let i = 0; i < labels.length; i++) {
                for (let j = 0; j < algorithms.length; j++) {
                    const algorithm = algorithms[j];
                    const timerIndex = historyGroup[i].findIndex((obj) => obj.type === algorithm);

                    if (timerIndex !== -1) {
                        listData[j].data.push(historyGroup[i][timerIndex].timer);
                    } else {
                        listData[j].data.push(null);
                    }
                }
            }
            setLabel(labels);
            setDatasets(listData);
        };
        fetchData();
    }, [historyGroup]);

    const options = {
        spanGaps: true,
        title: {
            display: true,
            text: "Thời gian thực hiện các thuật toán sắp xếp",
            fontColor: "#ffffff",
        },

        plugins: {
            title: {
                display: true,
                text: "Thời gian thực hiện các thuật toán sắp xếp",
            },
        },
        scales: {
            y: {
                type: "linear",
                display: true,
                position: "left",
                title: {
                    display: true,
                    text: "Thời gian (s)",
                },
            },
            x: {
                display: true,
                position: "left",
                title: {
                    display: true,
                    text: "Nhóm",
                },
            },
        },
        legend: {
            labels: {
                fontColor: "#ffffff",
            },
        },
    };
    return (
        // <div className=" mt-6 ">
        //     {data.map((item, index) => (
        //         <div className="mt-6 bg-white rounded-md p-4" key={index}>
        //             <Line data={item} options={options} />
        //             <h2 className="text-center font-medium text-[12px] mt-2 underline">
        //                 {/* Biểu đồ: Sắp xếp nhóm {item.test[0]} */}
        //             </h2>
        //         </div>
        //     ))}
        // </div>
        <div className=" mt-6 ">
            <div className="mt-6 bg-white rounded-md p-4">
                <Line
                    data={{
                        labels: label,
                        datasets: datasets,
                    }}
                    options={options}
                />
                <h2 className="text-center font-medium text-[12px] mt-2 underline">
                    {/* Biểu đồ: Sắp xếp nhóm {item.test[0]} */}
                </h2>
            </div>
        </div>
    );
};
