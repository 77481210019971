import React, { useState } from "react";
import image01 from "../../assets/images/QuickSort/quick-sort-0.1_0.png";
import image02 from "../../assets/images/QuickSort/quick-sort-0.2_0.png";
import image03 from "../../assets/images/QuickSort/quick-sort-partition-fourth-step.png";
import image04 from "../../assets/images/QuickSort/quick-sort-partition-second-step.png";
import image05 from "../../assets/images/QuickSort/quick-sort-partition-third-step.png";
import image06 from "../../assets/images/QuickSort/quick-sort-partition-fourth-step.png";
import image07 from "../../assets/images/QuickSort/quick-sort-partition-fifth-step.png";
import image08 from "../../assets/images/QuickSort/quick-sort-partition-sixth-step.png";
import image09 from "../../assets/images/QuickSort/quick-sort_1.png";

import MImage01 from "../../assets/images/MergeSort/merge-sort-example_0.png";
import MImage02 from "../../assets/images/MergeSort/merge-two-sorted-arrays.png";

import HImage01 from "../../assets/images/HeapSort/array-vs-heap-indices.png";
import HImage02 from "../../assets/images/HeapSort/max-heap-min-heap.png";
import HImage03 from "../../assets/images/HeapSort/heapify-base-case_0.png";
import HImage04 from "../../assets/images/HeapSort/heapify-when-children-are-heaps.png";
import HImage05 from "../../assets/images/HeapSort/heapfy-root-element-when-subtrees-are-max-heaps.png";
import HImage06 from "../../assets/images/HeapSort/build-max-heap.png";
import HImage07 from "../../assets/images/HeapSort/build-max-heap-0.png";
import HImage08 from "../../assets/images/HeapSort/build-max-heap-1.png";
import HImage09 from "../../assets/images/HeapSort/build-max-heap-2_01.png";

import SImage01 from "../../assets/images/ShellSort/shell-sort-0.0.png";
import SImage02 from "../../assets/images/ShellSort/shell-sort-0.1.png";
import SImage03 from "../../assets/images/ShellSort/shell-sort-0.2.png";
import SImage04 from "../../assets/images/ShellSort/shell-sort-0.3.png";
import SImage05 from "../../assets/images/ShellSort/shell-sort-0.4.png";
import SImage06 from "../../assets/images/ShellSort/shell-sort-step_4.png";
import SImage07 from "../../assets/images/ShellSort/shell-sort-step_5.png";
import HightLightCode from "../HighLightCode/HighLightCode";

function QuickSort(props) {
    const codeString = `
    var items = [5, 3, 7, 6, 2, 9];

    function swap(items, leftIndex, rightIndex) {
        var temp = items[leftIndex];
        items[leftIndex] = items[rightIndex];
        items[rightIndex] = temp;
    }

    function partition(items, left, right) {
        var pivot = items[Math.floor((right + left) / 2)];
        var i = left;
        var j = right;

        while (i <= j) {
            while (items[i] < pivot) {
                i++;
            }

            while (items[j] > pivot) {
                j--;
            }

            if (i <= j) {
                swap(items, i, j);
                i++;
                j--;
            }
        }

        return i;
    }

    function quickSort(items, left, right) {
        var index;

        if (items.length > 1) {
            index = partition(items, left, right);

            if (left < index - 1) {
                quickSort(items, left, index - 1);
            }

            if (index < right) {
                quickSort(items, index, right);
            }
        }

        return items;
    }

    var sortedArray = quickSort(items, 0, items.length - 1);
    console.log(sortedArray); //prints [2,3,5,6,7,9]
`;
    return (
        <div className="text-white ">
            <div>
                <h2 className="text-[24px] font-bold py-1">Ý tưởng</h2>
                <p className="py-2 italic">
                    Quicksort là một thuật toán sắp xếp dựa trên phương pháp <strong>chia để trị</strong>, nơi mà:
                </p>
                <ol className="ml-3 flex flex-col gap-2 mt-2">
                    <li>
                        1. Một mảng được chia thành các mảng con bằng cách chọn một phần tử pivot (phần tử được chọn từ
                        mảng). Trong quá trình chia mảng, phần tử pivot nên được đặt sao cho các phần tử nhỏ hơn pivot
                        được giữ ở phía bên trái và các phần tử lớn hơn pivot được đặt ở phía bên phải của pivot.
                    </li>
                    <li>
                        2. Các mảng con bên trái và bên phải cũng được chia bằng cách sử dụng cùng một phương pháp. Quá
                        trình này tiếp tục cho đến khi mỗi mảng con chỉ chứa một phần tử duy nhất.
                    </li>
                    <li>
                        3. Tại điểm này, các phần tử đã được sắp xếp. Cuối cùng, các phần tử được kết hợp để tạo thành
                        một mảng đã được sắp xếp.
                    </li>
                </ol>
            </div>

            <div>
                <h2 className="text-[24px] font-bold py-1">Hoạt động</h2>
                <ol className="ml-3 flex flex-col gap-2 mt-2">
                    <li>
                        <h3 className="font-medium text-[20px] mb-2">1. Chọn phần tử Pivot</h3>
                        <p className="py-2">
                            Có nhiều biến thể của thuật toán quicksort, trong đó phần tử pivot được chọn từ các vị trí
                            khác nhau. Ở đây, chúng ta sẽ chọn phần tử ở bên phải của mảng làm phần tử pivot.
                        </p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={image01}
                            alt=""
                        />
                    </li>
                    <li>
                        <h3 className="font-medium text-[20px] mb-2">2. Sắp xếp lại mảng</h3>
                        <p className="py-2">
                            Bây giờ, các phần tử của mảng được sắp xếp lại sao cho các phần tử nhỏ hơn phần tử pivot
                            được đặt ở bên trái và các phần tử lớn hơn pivot được đặt ở bên phải.
                        </p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={image02}
                            alt=""
                        />
                        <p className="py-2 italic">Đây là cách chúng ta sắp xếp lại mảng:</p>
                        <ul className="ml-3">
                            <li>
                                1. Một con trỏ được cố định tại phần tử pivot. Phần tử pivot được so sánh với các phần
                                tử bắt đầu từ chỉ số đầu tiên của mảng.
                                <img
                                    className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                                    src={image03}
                                    alt=""
                                />
                            </li>
                            <li>
                                2. Nếu phần tử lớn hơn phần tử pivot, một con trỏ thứ hai sẽ được thiết lập cho phần tử
                                đó.
                                <img
                                    className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                                    src={image04}
                                    alt=""
                                />
                            </li>
                            <li>
                                3. Bây giờ, phần tử pivot được so sánh với các phần tử khác. Nếu đạt được một phần tử
                                nhỏ hơn phần tử pivot, phần tử nhỏ hơn đó sẽ được hoán đổi với phần tử lớn hơn được tìm
                                thấy trước đó.
                                <img
                                    className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                                    src={image05}
                                    alt=""
                                />
                            </li>
                            <li>
                                4. Một lần nữa, quá trình này được lặp lại để đặt phần tử lớn hơn tiếp theo làm con trỏ
                                thứ hai. Và hoán đổi nó với một phần tử khác nhỏ hơn.
                                <img
                                    className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                                    src={image06}
                                    alt=""
                                />
                            </li>
                            <li>
                                5. Quá trình này tiếp tục cho đến khi đạt đến phần tử thứ hai từ cuối cùng của mảng.
                                <img
                                    className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                                    src={image07}
                                    alt=""
                                />
                            </li>
                            <li>
                                6. Cuối cùng, phần tử pivot được hoán đổi với con trỏ thứ hai.
                                <img
                                    className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                                    src={image08}
                                    alt=""
                                />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3 className="font-medium text-[20px] mb-2">3. Chia mảng con</h3>
                        <p className="py-2">
                            Các phần tử pivot lại được chọn cho các phần con bên trái và bên phải riêng biệt. Sau đó,
                            <strong> bước 2</strong> được lặp lại.
                        </p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={image09}
                            alt=""
                        />
                        <i>
                            Các mảng con được chia cho đến khi mỗi mảng con chỉ chứa một phần tử duy nhất. Tại điểm này,
                            mảng đã được sắp xếp.
                        </i>
                    </li>
                </ol>
            </div>
            <div>
                <h2 className="text-[24px] font-bold py-2">Mã nguồn trong Javascript</h2>
                <HightLightCode codeString={codeString} />
            </div>
            <div>
                <div className="mx-auto p-8 rounded shadow-md">
                    <h2 className="text-[24px] font-bold py-2">Độ phức tạp</h2>
                    <table className="w-full border border-gray-300">
                        <thead className="b">
                            <tr>
                                <th className="border p-2">Tốt nhất</th>
                                <th className="border p-2">Tệ nhất</th>
                                <th className="border p-2">Trung bình</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border text-center p-2">O(nlog n)</td>
                                <td className="border text-center p-2">O(n^2)</td>
                                <td className="border text-center p-2">O(nlog n)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
function MergeSort(props) {
    const codeString = `
    function mergeSort(arr) {
        if (arr.length <= 1) {
            return arr;
        }
    
        const middle = Math.floor(arr.length / 2);
        const left = arr.slice(0, middle);
        const right = arr.slice(middle);
    
        return merge(mergeSort(left), mergeSort(right));
    }
    
    function merge(left, right) {
        let result = [];
        let leftIndex = 0;
        let rightIndex = 0;
    
        while (leftIndex < left.length && rightIndex < right.length) {
            if (left[leftIndex] < right[rightIndex]) {
                result.push(left[leftIndex]);
                leftIndex++;
            } else {
                result.push(right[rightIndex]);
                rightIndex++;
            }
        }
    
        // Nối các phần tử còn lại từ cả hai mảng
        return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
    }
    
    var items = [5, 3, 7, 6, 2, 9];
    var sortedArray = mergeSort(items);
    console.log(sortedArray); // prints [2,3,5,6,7,9]
    
`;
    return (
        <div className="text-white ">
            <div>
                <h2 className="text-[24px] font-bold py-1">Ý tưởng</h2>
                <p className="py-2">
                    Merge Sort là một trong những thuật toán sắp xếp phổ biến nhất, dựa trên nguyên tắc của Thuật toán
                    <strong> Chia để Trị</strong>.
                </p>
                <p className="py-2">
                    Ở đây, một vấn đề được chia thành nhiều bài toán con. Mỗi bài toán con được giải quyết độc lập. Cuối
                    cùng, các bài toán con được kết hợp để tạo ra giải pháp cuối cùng.
                </p>
                <img className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full " src={MImage01} alt="" />
            </div>

            <div>
                <h2 className="text-[24px] font-bold py-1">Hoạt động</h2>
                <div>
                    <h3 className="text-[20px] font-bold py-1">Chiến lược chia để trị</h3>
                    <p className="py-2">
                        Sử dụng kỹ thuật<strong> Chia để Trị</strong>, chúng ta chia một vấn đề thành các vấn đề con.
                        Khi giải pháp cho mỗi vấn đề con đã sẵn sàng, chúng ta 'kết hợp' kết quả từ các vấn đề con để
                        giải quyết vấn đề chính.
                    </p>
                    <p className="py-2">
                        Giả sử chúng ta cần sắp xếp một mảng <code>A</code>. Một vấn đề con có thể là sắp xếp một phần
                        của mảng này, bắt đầu từ chỉ số <code>p</code> và kết thúc tại chỉ số <code>r</code>, được biểu
                        diễn là
                        <code> A[p..r]</code>.
                    </p>
                    <ol className="ml-3 flex flex-col gap-2 mt-2">
                        <li>
                            <h3 className="font-medium text-[20px] mb-2">1. Chia</h3>
                            <p className="py-2">
                                Nếu q là điểm giữa giữa <code>p</code> và <code>p</code>, chúng ta có thể chia mảng con
                                <code> A[p..r]</code> thành hai mảng con <code>A[p..q]</code> và <code>A[q+1, r]</code>.
                            </p>
                        </li>
                        <li>
                            <h3 className="font-medium text-[20px] mb-2">2. Trị</h3>
                            <p className="py-2">
                                Trong bước Trị, chúng ta cố gắng sắp xếp cả hai mảng con <code>A[p..q]</code> và{" "}
                                <code>A[q+1, r]</code>. Nếu chưa đạt đến trường hợp cơ bản, chúng ta lại tiếp tục chia
                                cả hai mảng con này và cố gắng sắp xếp chúng.
                            </p>
                        </li>
                        <li>
                            <h3 className="font-medium text-[20px] mb-2">3. Kết hợp</h3>
                            <p className="py-2">
                                Khi bước Trị đạt đến trạng thái cơ bản và chúng ta có hai mảng con đã sắp xếp là{" "}
                                <code>A[p..q]</code> và <code>A[q+1, r]</code> cho mảng <code>A[p..r]</code>, chúng ta
                                kết hợp kết quả bằng cách tạo ra một mảng đã sắp xếp <code>A[p..r]</code> từ hai mảng
                                con đã sắp xếp là <code>A[p..q]</code> và A[q+1, r].
                            </p>
                        </li>
                    </ol>
                </div>
                <div>
                    <h3 className="text-[20px] font-bold py-1">Hợp nhất</h3>
                    <p className="py-2">
                        Mọi thuật toán đệ quy đều phụ thuộc vào một trường hợp cơ bản và khả năng kết hợp kết quả từ các
                        trường hợp cơ bản đó. Merge sort cũng không ngoại lệ. Phần quan trọng nhất của thuật toán merge
                        sort chính là, như bạn đã đoán được, bước gộp (merge step).
                    </p>
                    <p className="py-2">
                        Bước gộp là giải pháp cho vấn đề đơn giản của việc gộp hai danh sách (mảng) đã sắp xếp để tạo ra
                        một danh sách lớn (mảng) đã sắp xếp.
                    </p>
                    <p className="py-2">
                        Thuật toán duy trì ba con trỏ, một cho mỗi trong hai mảng và một để duy trì chỉ số hiện tại của
                        mảng đã sắp xếp cuối cùng.
                    </p>
                    <img
                        className="rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                        src={MImage02}
                        alt=""
                    />
                </div>
            </div>
            <div className="">
                <h2 className="text-[24px] font-bold py-2">Mã nguồn trong Javascript</h2>
                <HightLightCode codeString={codeString} />
            </div>
            <div>
                <div className="mx-auto p-8 rounded shadow-md">
                    <h2 className="text-[24px] font-bold py-2">Độ phức tạp</h2>
                    <table className="w-full border border-gray-300">
                        <thead className="b">
                            <tr>
                                <th className="border p-2">Tốt nhất</th>
                                <th className="border p-2">Tệ nhất</th>
                                <th className="border p-2">Trung bình</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border text-center p-2">O(nlog n)</td>
                                <td className="border text-center p-2">O(nlog n)</td>
                                <td className="border text-center p-2">O(nlog n)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
function HeapSort(props) {
    const codeString = `function heapSort(arr) {
        // Xây dựng heap từ mảng
        buildHeap(arr);
    
        // Sắp xếp heap
        for (let i = arr.length - 1; i > 0; i--) {
            // Đổi chỗ phần tử đầu heap (là phần tử lớn nhất) với phần tử cuối cùng của heap
            swap(arr, 0, i);
            
            // Giảm kích thước của heap và tái tạo heap
            heapify(arr, 0, i);
        }
    
        return arr;
    }
    
    function buildHeap(arr) {
        const n = arr.length;
        // Xây dựng heap từ dưới lên (từ phần tử n/2 đến 0)
        for (let i = Math.floor(n / 2) - 1; i >= 0; i--) {
            heapify(arr, i, n);
        }
    }
    
    function heapify(arr, i, heapSize) {
        const left = 2 * i + 1;
        const right = 2 * i + 2;
        let largest = i;
    
        // So sánh với nút con trái
        if (left < heapSize && arr[left] > arr[largest]) {
            largest = left;
        }
    
        // So sánh với nút con phải
        if (right < heapSize && arr[right] > arr[largest]) {
            largest = right;
        }
    
        // Nếu nút lớn nhất không phải là nút hiện tại, đổi chỗ và tái tạo heap
        if (largest !== i) {
            swap(arr, i, largest);
            heapify(arr, largest, heapSize);
        }
    }
    
    function swap(arr, i, j) {
        const temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
    }
    
    var items = [5, 3, 7, 6, 2, 9];
    var sortedArray = heapSort(items);
    console.log(sortedArray); // prints [2,3,5,6,7,9]
    `;
    return (
        <div className="text-white ">
            <div>
                <h2 className="text-[24px] font-bold py-1">Ý tưởng</h2>
                <p className="py-2 ">
                    Heap Sort là một kỹ thuật sắp xếp dựa trên so sánh, dựa trên cấu trúc dữ liệu{" "}
                    <strong>Heap nhị phân</strong>. Nó tương tự như thuật toán selection sort trong đó chúng ta đầu tiên
                    tìm phần tử nhỏ nhất và đặt phần tử nhỏ nhất ở đầu. Lặp lại quy trình tương tự cho các phần tử còn
                    lại.
                </p>
                <p className="py-2 ">
                    Điểm quan trọng ở đây là heap sort không phải là một thuật toán sắp xếp so sánh thông thường. Thay
                    vào đó, nó sử dụng tính chất của <strong>cấu trúc dữ liệu heap</strong> để sắp xếp dãy số một cách
                    hiệu quả.
                </p>
                <p className="py-2 ">
                    Heap Sort hoạt động bằng cách tưởng tượng các phần tử của mảng như một loại cây nhị phân hoàn chỉnh
                    đặc biệt được gọi là heap.
                </p>
            </div>

            <div>
                <h2 className="text-[24px] font-bold py-1">Hoạt động</h2>
                <ol className="ml-3 flex flex-col gap-2 mt-2">
                    <li>
                        <h3 className="font-medium text-[20px] mb-2">
                            1. Mối quan hệ giữa chỉ số của mảng và các phần tử của cây
                        </h3>
                        <p className="py-2">
                            Một cây nhị phân hoàn chỉnh có một đặc điểm thú vị mà chúng ta có thể sử dụng để tìm các con
                            và cha của bất kỳ nút nào.
                        </p>
                        <p className="py-2">
                            Nếu chỉ số của bất kỳ phần tử nào trong mảng là <code>i</code>, thì phần tử ở chỉ số{" "}
                            <code>2i+1</code> sẽ trở thành con trái và phần tử ở chỉ số <code>2i+2</code> sẽ trở thành
                            con phải. Ngoài ra, cha của bất kỳ phần tử nào ở chỉ số <code>i</code> được xác định bằng
                            giới hạn dưới của <code>(i-1)/2</code>.
                        </p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={HImage01}
                            alt=""
                        />
                    </li>
                    <li>
                        <h3 className="font-medium text-[20px] mb-2">2. Cấu trúc dữ liệu Heap là gì?</h3>
                        <p className="py-2">
                            Heap là một cấu trúc dữ liệu đặc biệt dựa trên cây. Một cây nhị phân được coi là tuân theo
                            cấu trúc dữ liệu heap nếu:
                        </p>
                        <ul className="ml-3 list-disc">
                            <li>Nó là một cây nhị phân hoàn chỉnh.</li>
                            <li>
                                Tất cả các nút trong cây tuân theo tính chất rằng chúng lớn hơn tất cả các nút con của
                                chúng, nghĩa là phần tử lớn nhất là ở gốc và cả hai con của nó đều nhỏ hơn gốc và cứ như
                                vậy. Một heap như vậy được gọi là max-heap. Nếu thay vào đó, tất cả các nút đều nhỏ hơn
                                tất cả các nút con của chúng, thì nó được gọi là min-heap.
                            </li>
                        </ul>
                        <p className="py-2">Dưới đây là một ví dụ về biểu đồ cho Max-Heap và Min-Heap.</p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={HImage02}
                            alt=""
                        />
                    </li>
                    <li>
                        <h3 className="font-medium text-[20px] mb-2">3. Làm thế nào để "heapify" một cây</h3>
                        <p className="py-2">
                            Bắt đầu từ một cây nhị phân hoàn chỉnh, chúng ta có thể điều chỉnh nó để trở thành một
                            Max-Heap bằng cách chạy một hàm gọi là heapify trên tất cả các phần tử không phải lá của
                            heap.
                        </p>

                        <p className="py-2">
                            Vì heapify sử dụng đệ quy, nó có thể khá khó hiểu. Vì vậy, hãy trước hết nghĩ về cách bạn sẽ
                            thực hiện heapify cho một cây có chỉ ba phần tử.
                        </p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={HImage03}
                            alt=""
                        />
                        <p className="py-2">
                            Ví dụ trên cho thấy hai tình huống - một trong đó gốc là phần tử lớn nhất và chúng ta không
                            cần làm gì cả. Và một trường hợp khác trong đó gốc có một phần tử con lớn hơn và chúng ta
                            cần phải đổi chỗ để duy trì tính chất max-heap.
                        </p>
                        <p className="py-2">
                            Nếu bạn đã làm việc với các thuật toán đệ quy trước đây, bạn có thể đã nhận ra rằng điều này
                            phải là trường hợp cơ bản.
                        </p>
                        <p className="py-2">
                            Bây giờ hãy nghĩ về một tình huống khác trong đó có nhiều hơn một cấp độ trong cây.
                        </p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={HImage04}
                            alt=""
                        />

                        <p className="py-2">
                            Phần tử ở đỉnh không phải là một max-heap nhưng tất cả các cây con đều là max-heap.
                        </p>
                        <p className="py-2">
                            Để duy trì tính chất max-heap cho toàn bộ cây, chúng ta sẽ phải tiếp tục đẩy 2 xuống phía
                            dưới cho đến khi nó đạt đến vị trí đúng của nó.
                        </p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={HImage05}
                            alt=""
                        />
                        <p className="py-2">
                            Do đó, để duy trì tính chất max-heap trong một cây mà cả hai cây con đều là max-heap, chúng
                            ta cần chạy heapify trên phần tử gốc lặp đi lặp lại cho đến khi nó lớn hơn cả hai con của nó
                            hoặc trở thành một nút lá.
                        </p>
                        <p className="py-2">
                            Để duy trì tính chất max-heap cho toàn bộ cây, chúng ta sẽ phải tiếp tục đẩy 2 xuống phía
                            dưới cho đến khi nó đạt đến vị trí đúng của nó.
                        </p>
                    </li>
                    <li>
                        <h3 className="font-medium text-[20px] mb-2">3. Xây dựng max-heap</h3>
                        <p className="py-2">
                            Để xây dựng một max-heap từ bất kỳ cây nào, chúng ta có thể bắt đầu heapify từng cây con từ
                            dưới lên và kết thúc với một max-heap sau khi hàm được áp dụng cho tất cả các phần tử bao
                            gồm cả phần tử gốc.
                        </p>

                        <p className="py-2">
                            Trong trường hợp của một cây hoàn chỉnh, chỉ số đầu tiên của một nút không phải là lá được
                            xác định bởi n/2 - 1. Tất cả các nút khác sau đó đều là nút lá và do đó không cần phải được
                            heapify.
                        </p>
                        <p className="py-2">Vì vậy, chúng ta có thể xây dựng một max-heap như sau:</p>

                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={HImage06}
                            alt=""
                        />
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={HImage07}
                            alt=""
                        />
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={HImage08}
                            alt=""
                        />
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={HImage09}
                            alt=""
                        />
                        <p className="py-2">
                            Như được thể hiện trong biểu đồ ở trên, chúng ta bắt đầu bằng cách heapify các cây nhỏ nhất
                            ở đáy và dần dần di chuyển lên cho đến khi đạt đến phần tử gốc.
                        </p>
                        <h3 className="py-4 text-[20px] font-bold">Tổng quan về hoạt động của thuật toán Heap sort</h3>
                        <ul className="ml-3 flex flex-col gap-1 list-disc">
                            <li>
                                Vì cây đáp ứng tính chất Max-Heap, vì vậy phần tử lớn nhất được lưu trữ tại nút gốc.
                            </li>
                            <li>
                                <strong>Swap</strong>: Loại bỏ phần tử gốc và đặt nó ở cuối mảng (vị trí thứ nth). Đặt
                                phần tử cuối cùng của cây (heap) vào vị trí trống.
                            </li>
                            <li>
                                <strong>Remove</strong>: Giảm kích thước của heap xuống 1.
                            </li>
                            <li>
                                <strong>Heapify</strong>: Heapify lại phần tử gốc để có phần tử cao nhất ở gốc.
                            </li>
                            <li>
                                Quy trình này được lặp lại cho đến khi tất cả các phần tử của danh sách được sắp xếp.
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>
            <div className="">
                <h2 className="text-[24px] font-bold py-2">Mã nguồn trong Javascript</h2>
                <HightLightCode codeString={codeString} />
            </div>
            <div>
                <div className="mx-auto p-8 rounded shadow-md">
                    <h2 className="text-[24px] font-bold py-2">Độ phức tạp</h2>
                    <table className="w-full border border-gray-300">
                        <thead className="b">
                            <tr>
                                <th className="border p-2">Tốt nhất</th>
                                <th className="border p-2">Tệ nhất</th>
                                <th className="border p-2">Trung bình</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border text-center p-2">O(nlog n)</td>
                                <td className="border text-center p-2">O(nlog n)</td>
                                <td className="border text-center p-2">O(nlog n)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
function ShellSort(props) {
    const codeString = `function shellSort(arr) {
        const n = arr.length;
        let gap = Math.floor(n / 2);
    
        while (gap > 0) {
            for (let i = gap; i < n; i++) {
                let temp = arr[i];
                let j = i;
    
                while (j >= gap && arr[j - gap] > temp) {
                    arr[j] = arr[j - gap];
                    j -= gap;
                }
    
                arr[j] = temp;
            }
    
            gap = Math.floor(gap / 2);
        }
    
        return arr;
    }
    
    var items = [5, 3, 7, 6, 2, 9];
    var sortedArray = shellSort(items);
    console.log(sortedArray); // prints [2,3,5,6,7,9]
    `;
    return (
        <div className="text-white ">
            <div>
                <h2 className="text-[24px] font-bold py-1">Ý tưởng</h2>
                <p className="py-2 ">
                    Shell Sort là một phiên bản tổng quát của thuật toán <strong>insertion sort</strong>. Nó sắp xếp
                    trước các phần tử có khoảng cách lớn với nhau và giảm dần khoảng cách giữa các phần tử cần sắp xếp.
                </p>
                <p className="py-2">Khoảng cách giữa các phần tử được giảm dựa trên chuỗi được sử dụng. </p>
                <i className="py-2">
                    Chú ý: Hiệu suất của thuật toán shell sort phụ thuộc vào loại chuỗi được sử dụng cho một mảng đầu
                    vào cụ thể.
                </i>
            </div>

            <div>
                <h2 className="text-[24px] font-bold py-1">Hoạt động</h2>
                <ol className="ml-3 flex flex-col gap-2 mt-2">
                    <li>
                        <p className="py-2">1. Giả sử chúng ta cần sắp xếp mảng sau</p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={SImage01}
                            alt=""
                        />
                    </li>
                    <li>
                        <p className="py-2">
                            2. Chúng ta đang sử dụng chuỗi gốc của Shell <code>(N/2, N/4, ...1)</code> làm khoảng cách
                            trong thuật toán.
                        </p>
                        <p className="py-2 ml-3">
                            Trong vòng lặp đầu tiên, nếu kích thước mảng là <code>N = 8</code> thì các phần tử nằm ở
                            khoảng cách <code>N/2 = 4</code> được so sánh và đổi chỗ nếu chúng không theo thứ tự.
                        </p>
                        <ul className="ml-3 flex flex-col gap-1 list-disc">
                            <li>Phần tử thứ 0 được so sánh với phần tử thứ 4.</li>
                            <li>
                                Nếu phần tử thứ 0 lớn hơn phần tử thứ 4, thì phần tử thứ 4 được lưu trữ trước tiên trong
                                biến <code>temp</code> và phần tử thứ 0 (nghĩa là phần tử lớn hơn) được lưu trữ ở vị trí
                                thứ 4 và phần tử được lưu trữ trong biến <code>temp</code> được lưu trữ ở vị trí thứ 0.
                            </li>
                        </ul>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={SImage02}
                            alt=""
                        />

                        <p className="py-2 ml-3">Quá trình này tiếp tục cho tất cả các phần tử còn lại.</p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={SImage03}
                            alt=""
                        />
                        <p>
                            3. Trong vòng lặp thứ hai, một khoảng cách của <code>N/4 = 8/4 = 2</code> được chọn và một
                            lần nữa các phần tử nằm ở những khoảng cách này được sắp xếp.
                        </p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={SImage04}
                            alt=""
                        />
                        <i className="py-2 ml-3">Bạn có thể bị nhầm lẫn vào thời điểm này.</i>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={SImage05}
                            alt=""
                        />
                        <p className="py-2 ml-3">
                            Các phần tử ở vị trí thứ 4 và thứ 2 được so sánh. Các phần tử ở vị trí thứ 2 và thứ 0 cũng
                            được so sánh. Tất cả các phần tử trong mảng nằm ở khoảng cách hiện tại được so sánh.
                        </p>

                        <p className="py-2">4. Quá trình tương tự diễn ra với các phần tử còn lại.</p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={SImage06}
                            alt=""
                        />
                        <p className="py-2 ml-3">
                            Cuối cùng, khi khoảng cách là <code>N/8 = 8/8 = 1</code> thì các phần tử mảng nằm ở khoảng
                            cách là <code>1</code> được sắp xếp. Mảng bây giờ đã hoàn toàn được sắp xếp.
                        </p>
                        <img
                            className=" rounded-md my-5 bg-white w-[70%] object-cover mx-auto h-full"
                            src={SImage07}
                            alt=""
                        />
                    </li>
                </ol>
            </div>
            <div className="">
                <h2 className="text-[24px] font-bold py-2">Mã nguồn trong Javascript</h2>
                <HightLightCode codeString={codeString} />
            </div>
            <div>
                <h2 className="text-[24px] font-bold py-2">Độ phức tạp</h2>
                <div className="mx-auto p-8 rounded shadow-md">
                    <table className="w-full border border-gray-300">
                        <thead className="b">
                            <tr>
                                <th className="border p-2">Tốt nhất</th>
                                <th className="border p-2">Tệ nhất</th>
                                <th className="border p-2">Trung bình</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border text-center p-2">O(nlog n)</td>
                                <td className="border text-center p-2">O(n2)</td>
                                <td className="border text-center p-2">O(nlog n)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export { QuickSort, MergeSort, HeapSort, ShellSort };
