export function getShellSortAnimations(array) {
    const animations = [];

    let n = array.length;
    let gap = Math.floor(n / 2);

    while (gap > 0) {
        for (let i = gap; i < n; i++) {
            let temp = array[i];
            let j = i;

            while (j >= gap && array[j - gap] > temp) {
                animations.push([j, j - gap]);
                animations.push([j, array[j - gap]]);
                animations.push([j - gap, temp]);
                animations.push([j, j - gap]);

                array[j] = array[j - gap];
                j -= gap;
            }

            array[j] = temp;
        }

        gap = Math.floor(gap / 2);
    }
    return animations;
}
