import { Modal } from "@mui/material";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { HeapSort, MergeSort, QuickSort, ShellSort } from "../../components/Overview/Overview";

function About({ type, setModalType }) {
    const Sort = () => {
        switch (type) {
            case "Thuật Toán Quicksort":
                return <QuickSort />;
            case "Thuật Toán Mergesort":
                return <MergeSort />;
            case "Thuật Toán Heapsort":
                return <HeapSort />;
            case "Thuật Toán Shellsort":
                return <ShellSort />;
            default:
                return null;
        }
    };
    return (
        <Modal open={true} onClose={() => setModalType(null)} className="flex justify-center items-center ">
            <div className="w-[1000px] h-[80vh] p-5 relative rounded-[12px] overflow-y-auto bg-[#3a4d5c]">
                <AiOutlineCloseCircle
                    className="absolute top-5 right-5 text-white text-[30px] cursor-pointer"
                    onClick={() => setModalType(null)}
                />
                <h2 className="text-white text-center py-2 my-4 text-[24px] font-bold">{type}</h2>
                <Sort />
                <button
                    className="px-4 py-2 rounded-[4px] bg-blue-400 text-white hover:opacity-90 my-3 mt-6 float-right"
                    onClick={() => setModalType(null)}
                >
                    Đóng
                </button>
            </div>
        </Modal>
    );
}

export default About;
