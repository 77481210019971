import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { SortingHistoryChart } from "../SortingHistoryChart/SortingHistoryChart";

function History({ history, handleDeleteHistory }) {
    const [showModal, setShowModal] = useState(false);
    const [historyGroup, setHistoryGroup] = useState([]);

    useEffect(() => {
        var resultArrays = {};
        history.forEach(function (item) {
            var typeKey = "group" + item.group;

            if (!resultArrays[typeKey]) {
                resultArrays[typeKey] = [];
            }

            resultArrays[typeKey].push(item);
        });
        const newData = Object.values(resultArrays)?.map((group) => {
            const newHistory = [...group].reverse();
            return newHistory;
        });
        setHistoryGroup(newData);
    }, [history]);
    return (
        <>
            <button className="btn sort relative group" onClick={() => setShowModal(true)}>
                Lịch sử
            </button>
            <Modal open={showModal} onClose={() => setShowModal(false)} className="flex justify-center items-center ">
                <div className="w-[1000px] h-[80vh] p-5 relative rounded-[12px] overflow-y-auto bg-[#3a4d5c]">
                    <AiOutlineCloseCircle
                        className="absolute top-5 right-5 text-white text-[30px] cursor-pointer"
                        onClick={() => setShowModal(false)}
                    />
                    <h2 className="text-white text-center py-2 my-4 text-[32px] font-bold">Lịch sử sắp xếp</h2>

                    <h2 className="text-white text-center py-2 my-4 text-[24px] font-bold">Bảng chi tiết</h2>

                    <table className="w-full border border-gray-300 text-white">
                        <thead className="b">
                            <tr>
                                <th className="border p-2">STT</th>
                                <th className="border p-2">Nhóm</th>
                                <th className="border p-2">Thuật toán</th>
                                <th className="border p-2">Tốc độ</th>
                                <th className="border p-2">Số lượng</th>
                                <th className="border p-2">Thời gian hoàn thành</th>
                                <th className="border p-2">Thời gian thực hiện</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history?.map((item, index) => {
                                return (
                                    <tr
                                        key={index}
                                        className={`${
                                            item?.group === history[index + 1]?.group
                                                ? null
                                                : "border-b-[5px] border-slate-300"
                                        }`}
                                    >
                                        <td className="border text-center p-2">{index + 1}</td>
                                        <td className="border text-center p-2">{item.group}</td>
                                        <td className="border text-center p-2">{item.type}</td>
                                        <td className="border text-center p-2">{item.speed}</td>
                                        <td className="border text-center p-2">{item.size}</td>
                                        <td className="border text-center p-2">{item.timer} (s)</td>
                                        <td className="border text-center p-2">{item.date}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <h2 className="text-white text-center py-2 my-4 text-[24px] font-bold">Bảng Tổng quan</h2>
                    <table className="w-full border border-gray-300 text-white">
                        <thead className="b">
                            <tr>
                                <th className="border p-2">Lần thực hiện</th>
                                <th className="border p-2">QuickSort</th>
                                <th className="border p-2">MergeSort</th>
                                <th className="border p-2">HeapSort</th>
                                <th className="border p-2">ShellSort</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyGroup?.map((group, index) => (
                                <tr key={index}>
                                    <td className="border p-2 text-center">{group[0].group}</td>
                                    <td className="border p-2 text-center">
                                        {group.find((item) => item.type === "QuickSort")?.timer || "Không có giá trị"}
                                    </td>
                                    <td className="border p-2 text-center">
                                        {group.find((item) => item.type === "MergeSort")?.timer || "Không có giá trị"}
                                    </td>
                                    <td className="border p-2 text-center">
                                        {group.find((item) => item.type === "HeapSort")?.timer || "Không có giá trị"}
                                    </td>
                                    <td className="border p-2 text-center">
                                        {group.find((item) => item.type === "ShellSort")?.timer || "Không có giá trị"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {history.length === 0 && (
                        <p className="text-white text-center py-2 font-medium text-[18px]">
                            Không có hoạt động sắp xếp nào gần đây.
                        </p>
                    )}
                    <h2 className="text-white text-center py-2 my-4 text-[24px] font-bold">Biểu đồ thể hiện</h2>
                    <SortingHistoryChart historyGroup={historyGroup} />
                    <div className="flex gap-2 justify-end">
                        <button
                            className="px-4 py-2 rounded-[4px]  bg-[#999] text-white hover:opacity-90 my-3 mt-6 "
                            onClick={handleDeleteHistory}
                        >
                            Xóa lịch sử
                        </button>
                        <button
                            className="px-4 py-2 rounded-[4px]  bg-blue-400 text-white hover:opacity-90 my-3 mt-6 "
                            onClick={() => setShowModal(false)}
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default History;
